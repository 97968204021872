class UserAgent {
  static OS = [
    { name: 'Android', id: ['Android'] },
    { name: 'iOS', id: ['iPhone', 'iPad'] },
    { name: 'Windows', id: ['Win'] },
    { name: 'MacOS', id: ['Mac'] },
    { name: 'Chrome OS', id: ['CrOS'] },
    { name: 'Linux', id: ['Linux', 'X11'] },
  ]

  static BROWSER = [
    { name: 'Microsoft Edge', id: ['Edg'] },
    { name: 'Chrome', id: ['Chrome'] },
    { name: 'Safari', id: ['Safari'] },
    { name: 'Firefox', id: ['Firefox'] },
    { name: 'Internet Explorer', id: ['MSIE', 'Trident'] },
  ]

  static getOS = (userAgent: string): string => {
    for (const os of UserAgent.OS) {
      for (const id of os.id) {
        if (userAgent.includes(id)) return os.name
      }
    }
    return 'Unknown'
  }

  static getBrowser = (userAgent: string): string => {
    for (const browser of UserAgent.BROWSER) {
      for (const id of browser.id) {
        if (userAgent.includes(id)) return browser.name
      }
    }

    return 'Unknown'
  }
}

export default UserAgent
