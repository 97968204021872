import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

import { breakpoints } from 'utils/breakpoints'
import { FormValues } from 'meeting/catering/CateringForm'

interface PickerProps {
  onClick?: () => void
}

interface InputProps {
  min?: number
  max?: number
  type?: React.HTMLInputTypeAttribute
  onChange?: (e: React.FocusEvent<any>) => void
  onBlur?: (e: React.FocusEvent<any>) => void
}

interface Props extends PickerProps, InputProps {
  fieldType: 'picker' | 'smallInput' | 'bigInput' | 'text'
  inputId: keyof FormValues | 'time' | 'price'
  title?: string
  value?: string | number
  placeholder?: string
  error?: string
  disabled?: boolean
  disabledStyle?: boolean
}

const FormField = ({
  fieldType,
  title,
  placeholder,
  onClick,
  value,
  inputId,
  disabled,
  disabledStyle,
  error,
  min,
  max,
  type,
  onChange,
  onBlur,
}: Props) => {
  const [theme] = useTheme()

  const renderTitle = () => (!!title ? <FieldTitle htmlFor={inputId}>{title}</FieldTitle> : <></>)

  if (fieldType === 'picker') {
    return (
      <FieldContainer>
        {renderTitle()}
        <PickerFieldInputButton
          type="button"
          onClick={() => !disabled && !!onClick && onClick()}
          disabled={disabled}
          disabledStyle={disabledStyle}
          error={!!error}>
          <PickerFieldInputText disabled={disabledStyle}>{value || placeholder}</PickerFieldInputText>
          {!value && (
            <Icon
              name="chevron_bottom"
              color={disabledStyle ? theme.colors.lightGrey : theme.colors.primaryDark}
              size={16}
            />
          )}
        </PickerFieldInputButton>
        {error && !disabled && <FieldErrorText>{error}</FieldErrorText>}
      </FieldContainer>
    )
  }

  const inputProps = { onChange, onBlur, disabled }

  if (fieldType === 'smallInput') {
    return (
      <FieldContainer>
        {renderTitle()}
        <SmallInput
          value={value}
          id={inputId}
          type={type}
          onFocus={(e) => e.target.select()}
          min={min}
          max={max}
          error={!!error}
          {...inputProps}
        />
        {error && <FieldErrorText>{error}</FieldErrorText>}
      </FieldContainer>
    )
  }

  if (fieldType === 'bigInput') {
    return (
      <FieldContainer>
        {renderTitle()}
        <BigInput value={value} id={inputId} placeholder={placeholder} error={!!error} {...inputProps} />
      </FieldContainer>
    )
  }

  return (
    <FieldContainer>
      {renderTitle()}
      <PriceFieldContent id={inputId}>{value}</PriceFieldContent>
    </FieldContainer>
  )
}

export default FormField

const FieldContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`

const FieldTitle = styled.label`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0;
`

const PickerFieldInputButton = styled.button<{ disabledStyle?: boolean; error: boolean }>`
  background: none;
  color: inherit;
  border: none;
  padding: 12px;
  font: inherit;
  outline: inherit;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.disabledStyle ? 'not-allowed' : props.disabled ? 'auto' : 'pointer')};
  border-radius: 4px;
  border: 1px solid ${(props) => (props.error ? props.theme.colors.error : props.theme.colors.middleGrey)};
`

const PickerFieldInputText = styled.p<{ disabled?: boolean }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => (props.disabled ? props.theme.colors.lightGrey : props.theme.colors.iconicGrey)};
  margin: 0;
  text-align: left;
`

const SmallInput = styled.input<{ error: boolean }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.iconicGrey};
  border: 1px solid ${(props) => (props.error ? props.theme.colors.error : props.theme.colors.middleGrey)};
  border-radius: 4px;
  padding: 12px;
  align-items: center;
  text-transform: uppercase;
`

const BigInput = styled.textarea<{ error: boolean }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.iconicGrey};
  height: 100px;
  border: 1px solid ${(props) => (props.error ? props.theme.colors.error : props.theme.colors.middleGrey)};
  border-radius: 4px;
  padding: 12px;
  align-items: center;
  resize: none;
  ::placeholder {
    ${(props) => props.theme.fonts.subtitle};
    color: ${(props) => props.theme.colors.unavailable};
  }
  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: calc(100vw - 100px);
  }
`

const FieldErrorText = styled.p`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.error};
  margin: 0;
`

const PriceFieldContent = styled.p`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.primaryDark};
  background-color: ${(props) => props.theme.colors.contentBackground};
  margin: 0;
  border-radius: 4px;
  padding: 12px;
`
