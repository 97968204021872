import * as React from 'react'
import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import Button from 'components/button/Button'
import CloseModalButton from 'components/button/CloseModalButton'
import SearchBar from '../input/SearchBar'
import Icon from '../icons/Icon'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'
import sanitize from 'utils/Sanitizer'

import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

interface Option {
  label: string
  value: string
  customRender?: (selected: boolean) => JSX.Element
  disabled?: boolean
}

interface Props {
  title: string
  warning?: string
  data: Option[]
  selected?: string
  renderAdditionalData?: (value: any) => JSX.Element
  onItemSelected: (selected: string) => void
  withSearch?: boolean
  searchPlaceholder?: string
  buttonColor?: string
}

const PickerModal = ({
  title,
  warning,
  data,
  selected,
  renderAdditionalData,
  onItemSelected,
  withSearch,
  searchPlaceholder,
  buttonColor,
}: Props) => {
  const i18n = useI18n()

  const [current, setCurrent] = React.useState(selected)

  const [search, setSearch] = React.useState('')

  const filteredData = withSearch
    ? data.filter((d) =>
        search
          .split(' ')
          .every((q) => sanitize(d.label).includes(sanitize(q)) || sanitize(d.value).includes(sanitize(q)))
      )
    : data

  useEscKeyPressed(PickerPortal.close)

  const save = () => {
    if (current) {
      onItemSelected(current)
      PickerPortal.close()
    }
  }

  const renderItem = (item: Option) => {
    const active = item.value === current

    return (
      <ItemContainer key={item.value}>
        <ItemButton
          disabled={item.disabled}
          onClick={() => setCurrent(item.value)}
          aria-label={i18n.t(`accessibility.ariaLabels.booking.periodSelection${active ? 'Selected' : ''}`, {
            period: item.label,
          })}>
          {item.customRender ? (
            item.customRender(active)
          ) : (
            <>
              {item.label}
              {!!renderAdditionalData && renderAdditionalData(item.value)}
            </>
          )}
        </ItemButton>
      </ItemContainer>
    )
  }

  return (
    <FocusLock autoFocus={true} returnFocus>
      <MainContainer>
        <Back />

        <Container
          id="PickerModalDialog"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modalPickerHeading"
          tabIndex={-1}>
          <HeaderContainer>
            <Title id="modalPickerHeading">{title}</Title>
            <CloseModalButton nameIcon="cross" sizeIcon={30} onClick={PickerPortal.close} />
          </HeaderContainer>

          {!!warning && (
            <WarningContainer>
              <Icon name="info" size={24} />
              <WarningText>{warning}</WarningText>
            </WarningContainer>
          )}
          {withSearch && <SearchBar onChange={setSearch} value={search} placeholder={searchPlaceholder} />}

          <List>{filteredData.map(renderItem)}</List>

          <ButtonContainer>
            <Button color={buttonColor} label={i18n.t('common.validate')} onClick={save} disabled={!current} />
          </ButtonContainer>
        </Container>
      </MainContainer>
    </FocusLock>
  )
}

const MainContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Container = styled('div')`
  display: flex;
  margin: 20px;
  width: 520px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 30px;
  border-radius: 15px;
  gap: 30px;
  overflow: hidden;
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: calc(100vw - 80px);
    border-radius: 10px;
    gap: 20px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
`

const ButtonContainer = styled('div')`
  align-items: center;
`

const ItemButton = styled('button')<{ disabled?: boolean }>`
  ${(props) => props.theme.fonts.h3};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 15px;
  text-align: center;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'not-allowed')};
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
  display: flex;
  flex-grow: 1;
`

const ItemContainer = styled.li`
  display: flex;
  flex: 1;
`

const List = styled('ul')`
  list-style: none;
  padding: 0;
  overflow-y: auto;
  margin: 0;
`

const WarningContainer = styled.div`
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const WarningText = styled.div`
  ${(props) => props.theme.fonts.subtitle};
`

let modalRoot: HTMLElement | null

const PickerPortal = {
  open: (props: Props) => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <PickerModal {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default PickerPortal
