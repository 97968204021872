import * as React from 'react'
import styled from 'theme/styled-components'
import Provider from 'theme/Provider'
import theme from 'theme/Theme'

import Button from 'components/button/Button'
import CloseModalButton from 'components/button/CloseModalButton'
import TimePicker from 'react-time-picker'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'

import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

import './react-date-picker.css'

import { setHours, setMinutes, format, isBefore } from 'date-fns'

interface Props {
  title: string
  time?: Date
  min?: Date
  max?: Date
  onTimeSelected: (time: Date, timeEnd?: Date) => void
  timeEnd?: Date
  withEnd?: boolean
  buttonColor?: string
}

const TimePickerModal = ({ title, time, onTimeSelected, max, min, timeEnd, withEnd, buttonColor }: Props) => {
  const i18n = useI18n()

  const [current, setCurrent] = React.useState(time ? format(time, 'HH:mm') : undefined)
  const [currentEnd, setCurrentEnd] = React.useState(timeEnd && format(timeEnd, 'HH:mm'))

  const [inputValid, setInputValid] = React.useState(true)

  const keysPressed = React.useRef<string[]>([])
  const buttonContainerRef = React.useRef<HTMLDivElement>(null)
  const startTimeFilled = React.useRef<boolean>(false)

  const previousActiveElement = React.useRef<HTMLElement | null>(null)

  const updateBorderColorInput = (className: string, color: string) => {
    const element = document.getElementsByClassName(className)[0]?.firstChild as HTMLElement
    if (element) {
      element.style.border = `1px solid ${color}`
    }
  }

  const currentDate = React.useMemo(() => {
    if (!current) {
      return
    }
    const values = current.split(':')
    const hours = values[0] ? parseInt(values[0], 10) : undefined
    const minutes = values[1] ? parseInt(values[1], 10) : undefined

    if (hours === undefined || minutes === undefined) {
      return
    }
    setInputValid(true)
    updateBorderColorInput('startTimePicker', theme.colors.middleGrey)
    return setHours(setMinutes(time || new Date(), minutes), hours)
  }, [current])

  const currentEndDate = React.useMemo(() => {
    if (!currentEnd) {
      return
    }
    const values = currentEnd.split(':')
    const hours = values[0] ? parseInt(values[0], 10) : undefined
    const minutes = values[1] ? parseInt(values[1], 10) : undefined

    if (hours === undefined || minutes === undefined) {
      return
    }
    setInputValid(true)
    updateBorderColorInput('endTimePicker', theme.colors.middleGrey)
    return setHours(setMinutes(time || new Date(), minutes), hours)
  }, [currentEnd])

  useEscKeyPressed(TimePickerPortal.close)

  const save = () => {
    if (!currentDate) {
      return
    }

    onTimeSelected(currentDate, withEnd ? currentEndDate : undefined)
    closeTimePicker()
  }

  const checkInterval = React.useMemo(() => {
    if (currentDate && currentEndDate && isBefore(currentDate, currentEndDate)) {
      updateBorderColorInput('endTimePicker', theme.colors.middleGrey)
      return true
    } else {
      updateBorderColorInput('endTimePicker', theme.colors.error)
      return false
    }
  }, [currentDate, currentEndDate])

  const intervalIsValid = !!currentDate && !!currentEndDate && checkInterval

  const handleKeyDown = async (e: KeyboardEvent) => {
    const currentActiveElement = document.activeElement as HTMLElement
    const activeElementToCheck = previousActiveElement.current

    previousActiveElement.current = currentActiveElement

    e.key === 'Backspace' && keysPressed.current.pop()
    if (/\d/.test(e.key)) keysPressed.current.push(e.key)
    if (keysPressed.current.length >= 4) {
      keysPressed.current = []
      // Wait for the input to be updated before focusing on the next one
      setTimeout(() => {
        focusOnNext()
      }, 100)
    }
    if ((e.key === 'ArrowRight' || e.key === 'ArrowLeft') && activeElementToCheck && withEnd) {
      focusUsingArrows(e, activeElementToCheck)
    }
  }

  const getElementValue = (element: HTMLElement, name: string) => {
    const elem = element.querySelector(`input[name="${name}"]`) as HTMLInputElement
    return elem?.value
  }

  const handleFocusOut = () => {
    const startTimePickerElement = document.querySelector('.startTimePicker') as HTMLElement

    const startHour = parseInt(getElementValue(startTimePickerElement, 'hour24'))
    const startMinute = parseInt(getElementValue(startTimePickerElement, 'minute'))

    const isInvalidTime = (hour: number, minute: number) => hour > 23 || minute > 59

    if (isInvalidTime(startHour, startMinute)) {
      handleInvalidChange('startTimePicker')
    } else {
      setInputValid(true)
      updateBorderColorInput('startTimePicker', theme.colors.middleGrey)
    }

    if (withEnd) {
      const endTimePickerElement = document.querySelector('.endTimePicker') as HTMLElement

      const endHour = parseInt(getElementValue(endTimePickerElement, 'hour24'))
      const endMinute = parseInt(getElementValue(endTimePickerElement, 'minute'))
      if (
        isInvalidTime(endHour, endMinute) ||
        endHour < startHour ||
        (endHour === startHour && endMinute <= startMinute)
      ) {
        handleInvalidChange('endTimePicker')
      } else {
        setInputValid(true)
        updateBorderColorInput('endTimePicker', theme.colors.middleGrey)
      }
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('focusout', handleFocusOut)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('focusout', handleFocusOut)
    }
  }, [])

  const focusOnNext = () => {
    if (!startTimeFilled.current && withEnd) {
      const endTimePickerElement = document.getElementsByClassName('endTimePicker')[0]
      const input = endTimePickerElement?.querySelector('input')?.nextElementSibling as HTMLInputElement
      input?.focus()
      startTimeFilled.current = true
    } else {
      const button = buttonContainerRef.current?.firstChild as HTMLButtonElement
      button?.focus()
    }
  }

  const focusUsingArrows = (e: KeyboardEvent, activeElementToCheck: HTMLElement) => {
    const startTimePickerElement = document.getElementsByClassName('startTimePicker')[0]
    const endTimePickerElement = document.getElementsByClassName('endTimePicker')[0]
    if (
      e.key === 'ArrowRight' &&
      document.activeElement?.parentElement?.firstElementChild === startTimePickerElement.querySelector('input') &&
      activeElementToCheck.getAttribute('name') === 'minute'
    ) {
      const input = endTimePickerElement?.querySelector('input')?.nextElementSibling as HTMLInputElement
      input?.focus()
    }
    if (
      e.key === 'ArrowLeft' &&
      document.activeElement?.parentElement?.firstElementChild === endTimePickerElement.querySelector('input') &&
      activeElementToCheck.getAttribute('name') === 'hour24'
    ) {
      const input = startTimePickerElement?.querySelector('input[name="minute"]') as HTMLInputElement
      input?.focus()
    }
  }

  const closeTimePicker = () => {
    keysPressed.current = []
    startTimeFilled.current = false
    TimePickerPortal.close()
  }

  const handleInvalidChange = (className: string) => {
    setInputValid(false)
    updateBorderColorInput(className, theme.colors.error)
  }

  return (
    <MainContainer>
      <Back />

      <FocusLock autoFocus={true} returnFocus>
        <Container
          id="TimePickerModalDialog"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modalTimePickerHeading"
          tabIndex={-1}>
          <HeaderContainer>
            <Title id="modalTimePickerHeading">{title}</Title>
            <CloseModalButton nameIcon="cross" sizeIcon={30} onClick={closeTimePicker} />
          </HeaderContainer>

          <ContentContainer>
            {withEnd && <FromText>{i18n.t('common.from')}</FromText>}
            <TimePicker
              className="startTimePicker"
              value={current}
              onChange={setCurrent}
              maxTime={max}
              minTime={min}
              locale={i18n.langCode || 'fr-FR'}
              disableClock
              clearAriaLabel={i18n.t('accessibility.ariaLabels.booking.clearSelection')}
              autoFocus
              onInvalidChange={() => handleInvalidChange('startTimePicker')}
            />

            {withEnd && (
              <>
                <ToText>{i18n.t('common.to')}</ToText>
                <TimePicker
                  className="endTimePicker"
                  value={currentEnd}
                  onChange={setCurrentEnd}
                  isOpen={true}
                  maxTime={max}
                  minTime={current}
                  locale={i18n.langCode || 'fr-FR'}
                  disableClock
                  clearAriaLabel={i18n.t('accessibility.ariaLabels.booking.clearSelection')}
                  onInvalidChange={() => handleInvalidChange('endTimePicker')}
                />
              </>
            )}
          </ContentContainer>

          <ButtonContainer ref={buttonContainerRef}>
            <Button
              color={buttonColor}
              label={i18n.t('common.validate')}
              onClick={save}
              disabled={!currentDate || (withEnd && !intervalIsValid) || !inputValid}
            />
          </ButtonContainer>
        </Container>
      </FocusLock>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Container = styled('div')`
  width: 520px;
  margin: 20px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 40px;
  border-radius: 15px;
  gap: 30px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: calc(100vw - 80px);
    border-radius: 10px;
    gap: 20px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
`

const ButtonContainer = styled('div')`
  align-items: center;
`

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;

  input,
  span {
    font-family: ${(props) => props.theme.fonts.body.fontFamily} !important;
  }
`

const FromText = styled('p')`
  ${(props) => props.theme.fonts.body};
`

const ToText = styled('p')`
  ${(props) => props.theme.fonts.body};
`

let modalRoot: HTMLElement | null

const TimePickerPortal = {
  open: (props: Props) => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <TimePickerModal {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default TimePickerPortal
